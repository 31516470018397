<template>
  <div>
    <div class="flex justify-between items-center mt-10">
      <VTitle :title="$t('app.add_attestation')" class="section-title" />

      <LangDropdown :lang="lang" @update="onUpdateLang" />
    </div>

    <Form
      :is-loading="isRequesting"
      :lang="lang.value"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useAttestation from "@/composables/useAttestation";
import useCreate from "@/composables/useCreate";
import useRequest from "@/composables/useRequest";
import useLangDropdown from "@/composables/useLangDropdown";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    LangDropdown
  },
  setup() {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // CUSTOM COMPOSABLES
    const { endpoint, route } = useAttestation();
    const { lang, updateLang } = useLangDropdown();
    const { onClickCancel } = useCreate(endpoint, route);
    const { isRequesting, request } = useRequest();

    // COMPUTED
    const documentTitle = `${t("app.add")} - ${t("app.attestations", 2)} - ${t(
      "app.administration"
    )}`;

    // Methods
    const onClickSave = async data => {
      const response = await request({
        endpoint: `${endpoint}.create`,
        data
      });

      if (response?.payload?.message?.status === "Success") {
        router.push({
          name: `${route}-details`,
          params: {
            id: response.payload?.data?.id
          }
        });
      }
    };

    return {
      documentTitle,
      onClickSave,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang,
      // useCreate
      isRequesting,
      onClickCancel
    };
  }
};
</script>
